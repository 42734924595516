import refs from '../modules/refs'
import revealMixin from '../mixins/reveal'

class SectionIntroElement extends HTMLElement {
  refs = {}

  async connectedCallback() {
    await new Promise(resolve => setTimeout(resolve))
    this.refs = refs(this)

    this.revealInit(this)
  }
}

Object.assign(SectionIntroElement.prototype, revealMixin)

customElements.define('section-intro', SectionIntroElement)
