import { tween } from 'shifty'

export default {
  revealInit(els, delay = 0, tween = 1) {
    const intObsserver = new IntersectionObserver((entries) => {
      entries
        .filter(item => item.isIntersecting)
        .forEach(item => {
          item.target.dataset.reveal = '1'
          if (tween === 2) { 
            this.revealTween2(item.target, delay)
          }
          else {
            this.revealTween1(item.target, delay)
          }
          intObsserver.unobserve(item.target)
        })
    }, {
      threshold: Math.random() * 0.3
    })

    if (els?.length) {
      els.forEach(el => {
        el.dataset.reveal = '0'
        intObsserver.observe(el)
      })
    }
    else {
      if (els) {
        els.dataset.reveal = '0'
        intObsserver.observe(els)
      }
    }
  },

  revealTween1(el, delay = 0) {
    tween({
      from: {
        opacity: 0,
        y: 1.5
      },
      to: {
        opacity: 1,
        y: 0
      },
      duration: 900,
      easing: 'easeInOutExpo',
      delay,
      render: (params) => {
        el.style.opacity = params.opacity
        el.style.transform = `translateY(${params.y}rem)`
      }
    })
  },

  revealTween2(el, delay = 0) {
    tween({
      from: {
        opacity: 0,
        scale: 0.8,
        rotate: Math.random() * 90
      },
      to: {
        opacity: 1,
        scale: 1,
        rotate: 0
      },
      duration: 900,
      easing: 'easeInOutExpo',
      delay,
      render: (params) => {
        el.style.opacity = params.opacity
        el.style.transform = `scale(${params.scale}) rotate(${params.rotate}deg)`
      }
    })
  }
}
