import refs from '../modules/refs'
import revealMixin from '../mixins/reveal'

class CoinImageElement extends HTMLElement {
  refs = {}

  async connectedCallback() {
    await new Promise(resolve => setTimeout(resolve))
    this.refs = refs(this)

    if (this.refs.items?.length) {
      this.refs.items.forEach(el => this.revealInit(el, Math.random() * 1000, 2))
    }
  }
}

Object.assign(CoinImageElement.prototype, revealMixin)

customElements.define('coin-image', CoinImageElement)
