export default function refs(el) {
  const refs = {}
  
  el.querySelectorAll('[data-ref]').forEach((refEl) => {
    let key = refEl.dataset.ref
    const keyMatch = key.match(/^\[(.+)\]$/)
    if (keyMatch) {
      key = keyMatch[1]
      if (!refs[key]) {
        refs[key] = [refEl]
      } else {
        refs[key].push(refEl)
      }
    } else {
      refs[key] = refEl
    }
    el.removeAttribute('data-ref')
  })
  
  return refs
}
