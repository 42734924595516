import refs from '../modules/refs'
import revealMixin from '../mixins/reveal'

class SectionAltBoxesElement extends HTMLElement {
  refs = {}

  async connectedCallback() {
    await new Promise(resolve => setTimeout(resolve))
    this.refs = refs(this)

    if (this.refs.items?.length) {
      this.refs.items.forEach(el => this.revealInit(el))
    }
    if (this.refs.shapes?.length) {
      this.refs.shapes.forEach(el => this.revealInit(el, Math.random() * 500))
    }
  }
}

Object.assign(SectionAltBoxesElement.prototype, revealMixin)

customElements.define('section-altboxes', SectionAltBoxesElement)
