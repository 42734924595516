import refs from '../modules/refs'
import revealMixin from '../mixins/reveal'

class SectionBoxesElement extends HTMLElement {
  refs = {}

  async connectedCallback() {
    await new Promise(resolve => setTimeout(resolve))
    this.refs = refs(this)

    this.revealInit(this)
  }
}

Object.assign(SectionBoxesElement.prototype, revealMixin)

customElements.define('section-boxes', SectionBoxesElement)
