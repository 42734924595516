import refs from '../modules/refs'

class SectionHeroElement extends HTMLElement {
  refs = {}

  async connectedCallback() {
    await new Promise(resolve => setTimeout(resolve))
    this.refs = refs(this)
  }
}

customElements.define('section-hero', SectionHeroElement)
