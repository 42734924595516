import refs from '../modules/refs'
import revealMixin from '../mixins/reveal'

class SliderElement extends HTMLElement {
  refs = {}
  state = {}

  async connectedCallback() {
    await new Promise(resolve => setTimeout(resolve))
    this.refs = refs(this)
    
    this.slider = new Swiper(
      this.refs.slider,
      {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        }
      }
    )
    this.slider.on('slideChange', () => {
      this.refs.index.textContent = this.slider.realIndex + 1
    })

    this.refs.next.addEventListener('pointerup', () => this.slider.slideNext())
    this.refs.prev.addEventListener('pointerup', () => this.slider.slidePrev())

    this.revealInit(this)
  }
}

Object.assign(SliderElement.prototype, revealMixin)

customElements.define('section-slider', SliderElement)
